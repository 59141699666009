import { Component, Input } from '@angular/core';
import { FrequenciaMesDto } from '@core/dto/minhas-turmas/perfil-aluno/frequencia-mes.dto';

@Component({
  selector: 'app-frequency-table',
  templateUrl: './frequency-table.component.html',
  styleUrls: ['./frequency-table.component.scss']
})
export class FrequencyTableComponent {

  dias = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
  meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  @Input() frequencia: FrequenciaMesDto[];

  presenca(mes: string, dia: any) {
    const frequencia = this.frequencia?.find((m) => m.mes === mes)?.dias.find(d => Number(d.dia) === Number(dia));
    return this.classColor(frequencia);
  }

  classColor(frequencia: any) {
    if (frequencia === undefined) {
      return '';
    }

    if (frequencia?.presenca) {
      return 'presente';
    }

    if (frequencia?.ausenciaJustificada) {
      return 'ausente-justificado';
    }

    return 'ausente';
  }

  totalDeFaltas(mes) {
    const faltas = this.frequencia?.find((m) => m.mes === mes)?.dias.filter(x => x.presenca === false).length;
    return faltas;
  }
}
