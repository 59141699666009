<ul class="nav nav-tabs" [ngClass]="{ 'w-100': tabAllSize }" role="tablist">
  <li *ngFor="let tab of tabs" [ngClass]="{ 'w-100': tabAllSize }">
    <div
      class="{{ tab.class }}"
      id="{{ tab.id }}"
      role="tab"
      aria-controls="dados"
      [ngClass]="{ active: isCurrentTab(tab.id) }"
      (click)="setCurrentTab(tab.id)"
    >
      {{ tab.nome }}
    </div>
  </li>
</ul>
