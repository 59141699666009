import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Util } from '../../utils/util';
import { Calendar } from 'primeng/calendar'; // Altere para a importação correta do PrimeNG 9

@Component({
  selector: 'app-date-picker-range',
  templateUrl: './date-picker-range.component.html',
  styleUrls: ['./date-picker-range.component.scss'],
})
export class DatePickerRangeComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() name: string = '';
  @Input() customClass: any = '';
  @Input() id: string;

  @Input() view: string = 'date';
  @Input() dateFormat: string = 'dd/mm/yy';
  @Input() placeholder: string = 'dd/mm/aaaa';

  @Input() maxDate: Date = null;
  @Input() minDate: Date = null;

  @Input() yearRange = '2000:2030';
  @Input() showButtonBar = true;
  rangeDates: Date[];

  @Output() onChange = new EventEmitter<any>();
  @Output() onFocusOut = new EventEmitter<any>();
  @Output() dateSelected = new EventEmitter<Date[]>();

  @ViewChild('datePick') datePickElement: Calendar;

  changeSubscription: Subscription;

  locale = Util.getBrasilLocales();

  ngOnInit(): void {
    this.changeSubscription = this.formControl.valueChanges.subscribe(
      (value) => {
        if (this.equalsArray(value, this.rangeDates)) {
          this.changeRangeDates();
        } else if (!value) {
          this.rangeDates = null;
        }
      }
    );
    if (!this.equalsArray(this.formControl.value, this.rangeDates) && this.formControl.value?.length) {
      this.rangeDates = this.formControl.value?.map(date => new Date(date));
      this.changeRangeDates();
    }
  }

  equalsArray(array1: any[], array2: any[]): boolean {
    if (!array1 || !array2) {
      return false;
    }
    if (array1.length !== array2.length) {
      return false;
    }
    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }
    return true;
  }

  ngOnDestroy(): void {
    this.changeSubscription.unsubscribe();
   }

  get formControl(): FormControl {
    return this.form.get(this.name) as FormControl;
  }

  get invalid(): boolean {
    return this.form.get(this.name).invalid;
  }

  get disabled() {
    return this.formControl.disabled;
  }

  change() {
    this.changeRangeDates();
    this.formControl.markAsTouched();
    this.onChange.emit(this.formControl.value);
    this.onFocusOut.emit();
    this.dateSelected.emit(this.rangeDates);
  }

  changeRangeDates() {
    const filteredDates = this.rangeDates?.filter(date => date !== null);
    if (filteredDates?.length === 2) {
      const formattedValue = this.rangeDates.map(date => this.getValueFormatted(date));
      this.formControl.setValue(formattedValue);
    } else {
      this.formControl.setValue(this.getValueFormatted(filteredDates));
    }
  }

  blur() {
    this.formControl.markAsTouched();
    this.onFocusOut.emit(this.formControl.value);
  }

  getValueFormatted(value: any) {
    if (Array.isArray(value)) {
      return value.map(date => (date ? moment(date).format('YYYY-MM-DD HH:mm:SS') : ''));
    } else {
      return value ? moment(value).format('YYYY-MM-DD HH:mm:SS') : '';
    }
  }

}
