import {Component, EventEmitter, Input, Output} from '@angular/core';

type Tab = {
  nome: string;
  id: string;
  class: string;
  href?: string;
};

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
})
export class TabsComponent {
  @Input() public tabs: Array<Tab> = [];
  @Input() currentTab: string;
  @Input() tabAllSize = false;

  @Output() changeTab = new EventEmitter<string>();

  isCurrentTab(tab: string) {
    return this.currentTab === tab;
  }

  setCurrentTab(value: string) {
    this.currentTab = value;
    this.changeTab.emit(value);
  }
}
