<div class="brand-image">
  <p><em class="fas fa-compress"></em> SIAGE</p>
</div>
<div class="access-dinied">
  <p class="icon"><em class="fa fa-lock"></em></p>
  <h2 class="title">Acesso Negado...</h2>
  <p class="description">Você não tem permissão para acessar essa Página.</p>
  <p class="code">403</p>
</div>

