import {Component, Input} from '@angular/core';
import {Util} from '../../utils/util';

@Component({
  selector: 'app-generic-data-atualizacao',
  templateUrl: './generic-data-atualizacao.component.html',
})
export class GenericDataAtualizacaoComponent {
  @Input() rodape?: any;

  get data() {
    if (this.rodape?.dataAlteracao) {
      return this.formatDate(this.rodape?.dataAlteracao);
    }
    return this.formatDate(this.rodape?.dataCriacao);
  }

  formatDate(date: string) {
    return Util.formatDateHours(date);
  }

  get responsavel() {
    return this.rodape?.profissionalResponsavel;
  }
}
