import { Component, OnInit } from '@angular/core';
import { MENUS_NAVBAR } from './navbar-menu.constant';
import { MENUS_INFOBAR } from './navbar-infobar-menu.constant';
import { AuthService } from '@core/services/auth.service';
import { StorageService } from '@core/services/storage.service';
import { FilterStateService } from '../../../../../shared/services/filter-state.service';

@Component({
  selector: 'app-navbar-auth',
  templateUrl: './navbar-auth.component.html',
  styleUrls: ['./navbar-auth.component.scss']
})
export class NavbarAuthComponent implements OnInit {
  public menus = MENUS_NAVBAR;
  public menusInfoBar = MENUS_INFOBAR;
  public usuarioSession: any;

  constructor(
    private readonly authService: AuthService,
    private readonly storageService: StorageService,
    private readonly filterStateService: FilterStateService
  ) { }

  ngOnInit(): void {
    this.usuarioSession = this.storageService.getFromLocalStorage('usuario', true);
  }

  get ambienteQA() {
    return window.location.href.includes('codatahml');
  }

  get anoLetivo(){
    return this.storageService.getFromLocalStorage('anoLetivo', true).ano;
  }

  get matricula(){
    return this.authService.getCurrentMatriculaNomeEscola().matricula;
  }

  clearAllFilters(): void {
    this.filterStateService.clearAllFilters();
  }
}
