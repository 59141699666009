import { Pipe, PipeTransform } from "@angular/core";
import { SemestreEnum } from "@core/enums/semestre.enum";

@Pipe({
    name: 'semestre'
})
export class SemestrePipe implements PipeTransform {

    transform(value: string): string {
        const semestre = new SemestreEnum();
        return semestre.getName(value);
    }

}