<ng-content></ng-content>
<footer class="row">
  <div class="col-12 col-md-12">
    <button
      id="btn-buscar"
      type="submit"
      (click)="onSubmit()"
      title="Buscar"
      [disabled]="disabled"
      class="btn rounded-pill btn-primary float-right ml-md-1 mb-2 col-md-auto"
    >
      Buscar
    </button>
    <button
      id="btn-limpar"
      (click)="onClear()"
      title="Limpar"
      type="button"
      class="btn rounded-pill btn-outline-primary float-right ml-md-1 mb-2 col-md-auto"
    >
      Limpar
    </button>
  </div>
</footer>

