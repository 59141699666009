import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterStateService {
  private filterState: { [key: string]: any } = {};
  private readonly filtersCleared = new Subject<void>();

  filtersCleared$: Observable<void> = this.filtersCleared.asObservable();

  setFilter(crudKey: string, filterForm: any, page: number): void {
    this.filterState[crudKey] = { ...filterForm };
    if (page) {
      this.filterState[crudKey].page = page;
    }
  }

  getFilter(crudKey: string): any {
    return this.filterState[crudKey] || null;
  }

  clearFilter(crudKey: string): void {
    delete this.filterState[crudKey];
  }

  getCurrentPage(crudKey: string): number {
    return this.filterState[crudKey]?.page || 1;
  }

  clearAllFilters(): void {
    this.filterState = {};
    this.filtersCleared.next();
  }
}
