import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { EventoInfoDto } from '@core/dto/evento/evento-info.dto';
import { CalendarioMensalInfoDto } from '@core/dto/minhas-turmas/registro-aula-frequencia/calendario-mensal-info.dto';
import { Util } from '../../utils/util';

interface CalendarioMensal {
  date: Date;
  registrado: boolean;
  selected: boolean;
  show: boolean;
  tooltip?: string;
  naoHaAula?: boolean;
  cor?: string;
}

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss'],
})
export class CalendarioComponent implements OnInit, OnChanges {
  @Input() month: number = 0;
  @Input() year: number = 0;
  @Input() selectedDays: number[] = [];
  @Input() dateSelected;
  @Input() checkRegistroAula: boolean;
  @Input() showTooltip: boolean = false;
  @Input() tooltipValue;
  @Input() ocultarNaoHaAulas = false;
  @Input() eventos?: any[] = [];
  @Input() quadroHorarioDiaLetivoList?: Array<CalendarioMensalInfoDto> = [];
  @Output() onClick = new EventEmitter();

  dataAtual: Date = new Date();
  diasCalendario: CalendarioMensal[] = [];
  tooltipNaoHaAula =
    'Não corresponde aos dias de aulas deste componente para esta turma conforme quadro de horários.';

  ngOnInit() {
    this.construirCalendario();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.selectedDays ||
      changes.month ||
      changes.quadroHorarioDiaLetivoList
    ) {
      this.construirCalendario();
    }
  }

  compareArrayDate(dates1: Date[], dates2: Date[]) {
    return (
      dates1?.length === dates2?.length &&
      dates1?.every((date, index) => date.getTime() === dates2[index].getTime())
    );
  }

  construirCalendario() {
    const primeiroDiaDaSemana = 0; // domingo
    const ultimoDiaDaSemana = 6; // sábado

    this.diasCalendario = [];

    // Vai subtraindo -1 até chegarmos no primeiro dia da semana
    const dataInicial = new Date(this.year, this.month, 1);
    while (dataInicial.getDay() !== primeiroDiaDaSemana) {
      dataInicial.setDate(dataInicial.getDate() - 1);
    }

    // Vai somando +1 até chegarmos no último dia da semana
    const dataFinal = new Date(this.year, this.month + 1, 0);
    while (dataFinal.getDay() !== ultimoDiaDaSemana) {
      dataFinal.setDate(dataFinal.getDate() + 1);
    }

    for (
      const data = new Date(dataInicial.getTime());
      data <= dataFinal;
      data.setDate(data.getDate() + 1)
    ) {
      const day = new Date(data.getTime());
      const dateMes: CalendarioMensal = {
        date: day,
        show: this.canView(day),
        registrado: this.hasValue(day),
        selected: this.dateClicked(day),
        tooltip: this.naoHaAula(day)
          ? this.tooltipNaoHaAula
          : this.getTooltipValue(day),
        naoHaAula: this.naoHaAula(day),
        cor: this.eventos?.find(x => Util.equalDayMonthYear(new Date(x.data), day))?.cor,

      };
      this.diasCalendario.push(dateMes);
    }
    return this.diasCalendario;
  }

  alterarMes(offsetMes: number) {
    this.dataAtual.setMonth(this.dataAtual.getMonth() + offsetMes);
    this.dataAtual = new Date(this.dataAtual.getTime());
    this.construirCalendario();
  }

  public hasValue(currentDate: Date): boolean {
    return this.selectedDays.some(
      (day) =>
        new Date(this.year, this.month, day).getTime() === currentDate.getTime()
    );
  }

  public dateClicked(date) {
    return date.getTime() === this.dateSelected?.getTime();
  }

  public canView(dia) {
    return dia.getMonth() === this.month;
  }

  public naoHaAula(dia: Date) {
    const diaQuadroHorario = this.quadroHorarioDiaLetivoList?.find(
      (quadroHorarioDiaLetivo) => quadroHorarioDiaLetivo.dia === dia.getDate()
    );
    return (
      diaQuadroHorario &&
      !diaQuadroHorario?.deveRegistrar &&
      !this.hasValue(dia)
    );
  }

  public getTooltipValue(dia: Date) {
    if (this.showTooltip && this.tooltipValue?.length > 0) {
      const tooltip = this.tooltipValue.find((tooltip) => {
        const tooltipDay = tooltip.dia;
        const day = dia.getDate();
        return tooltipDay === day;
      });
      return tooltip ? tooltip.tooltipMsg : null;
    } else {
      return null;
    }
  }

  public onClickEmit(dia) {
    if (this.canView(dia.date) && !dia.naoHaAula) {
      this.onClick.emit(dia.date);
    }
  }
}
