import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnChanges {
  @Input() id;
  @Input() title;
  @Input() customClass = '';
  @Input() theme: 'primary' | 'secondary';
  @Input() inputState = false;

  @Output() onClick = new EventEmitter<any>();

  public state: boolean = false;

  constructor() {
    this.state = this.inputState;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputState) {
      this.state = changes.inputState.currentValue;
    }
  }

  click() {
    this.onClick.emit(this.state);
    this.state = !this.state;
  }
}
