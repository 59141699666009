import { EnumBase } from "@core/models/enum-base.model";

export class SemestreEnum extends EnumBase {
    constructor() {
        super([
          { value: 'SEMESTRE_1', name: '1º Semestre' },
          { value: 'SEMESTRE_2', name: '2º Semestre' },
        ]);
    }
}
