import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonSubmit } from 'src/app/core/models/button-submit.model';
import { ErrorMessage } from 'src/app/core/models/error-message.model';
import { SweetalertCustom } from 'src/app/shared/utils/sweetalert-custom';
import { Util } from 'src/app/shared/utils/util';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly baseUrl = environment.apiUrl;
  private readonly namespaceProject = environment.namespaceProject;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly router: Router,
    private readonly storageService: StorageService
  ) { }

  /**
   * Obtem o token da sessão para utilizar nas requisições
   */
   public headerOptions(): any {
    // tratar caso tenha o token
    const TOKEN = this.storageService.getFromLocalStorage('token')
     ? 'Bearer ' + this.storageService.getFromLocalStorage('token') : '';

    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: TOKEN
      }),
      observe: 'response'
    };
  }

  public login(errorMessage: ErrorMessage, obj: any, buttonSubmit?: ButtonSubmit) {

    buttonSubmit.buttonText = 'ACESSANDO';
    buttonSubmit.buttonSubmited = true;

    this.httpClient.post(this.baseUrl + 'usuario/login', JSON.stringify(obj), this.headerOptions())
      .subscribe(
        (response: any) => {
          this.setValueButtonSubmeted(buttonSubmit);
          if (response.status === 201 || response.status === 200) {
            this.successSend(response.body);
          }
        },
        (error: any) => {
          Util.clearErrorMessage(errorMessage);
          Util.setErrorMessage(errorMessage, error, 'warning');
          this.setValueButtonSubmeted(buttonSubmit);
        }
      );
  }

  /**
   * Seta as infomações do usuário e token do localStorage
   * @param response Response da requisição
   */
   public successSend(response) {
    const usuario = response.data;
    this.storageService.setInLocalStorage('token', response.data.token);
    this.storageService.setInLocalStorage('usuario', usuario);
    this.storageService.setInLocalStorage('perfil', response.data.perfil.descricao);
    if (response.data.primeiroAcesso) {
      return this.router.navigate(['/auth/primeiro-acesso']);
    }
    this.router.navigate(['/auth/selecionar-ano-matricula']);
  }

  /**
   * Seta o nome e habilita a ação de submissão
   * @param buttonSubmit Botão de submissão
   */
   public setValueButtonSubmeted(buttonSubmit) {
    buttonSubmit.buttonText = 'ACESSAR';
    buttonSubmit.buttonSubmited = false;
  }

  /**
   * Função de alterar senha
   * @param errorMessage Menssagem de erro
   * @param obj Objeto da transmissão
   * @param buttonSubmit Botão de submissão
   */
   public changePassword(errorMessage: ErrorMessage, obj: any, buttonSubmit?: ButtonSubmit) {
    buttonSubmit.buttonText = 'ACESSANDO';
    buttonSubmit.buttonSubmited = true;

    this.httpClient.post(this.baseUrl + 'usuario/alterar-senha', JSON.stringify(obj), this.headerOptions())
      .subscribe(
        (response: any) => {
          this.setValueButtonSubmeted(buttonSubmit);
          if (response.status === 201 || response.status === 200) {
            this.successSendRedirectChanged('Senha', 'Sua senha foi alterada com sucesso, faça o login novamente para valida-la.');
          }
        },
        (error: any) => {
          Util.clearErrorMessage(errorMessage);
          Util.setErrorMessage(errorMessage, error, 'warning');
          this.setValueButtonSubmeted(buttonSubmit);
        }
      );
  }

  /**
   * Função de redefinição de senha
   * @param errorMessage Menssagem de erro
   * @param obj Objeto de transmissão
   * @param buttonSubmit Botão de submissão
   */
   public redefinePassword(errorMessage: ErrorMessage, obj: any, buttonSubmit?: ButtonSubmit) {
    buttonSubmit.buttonText = 'REDEFININDO SENHA';
    buttonSubmit.buttonSubmited = true;

    this.httpClient.patch(this.baseUrl + 'usuario/resetar-senha', JSON.stringify(obj), this.headerOptions())
      .subscribe(
        (response: any) => {
          this.setValueButtonSubmitedReset(buttonSubmit);
          if (response.status === 201 || response.status === 200) {
            this.successSendResetPassword();
          }
        },
        (error: any) => {
          Util.clearErrorMessage(errorMessage);
          Util.setErrorMessage(errorMessage, error, 'warning');
          this.setValueButtonSubmitedReset(buttonSubmit);
        }
      );
  }

  /**
   * Seta o nome e habilita a ação de submissão
   * @param buttonSubmit Botão de submissão
   */
   public setValueButtonSubmitedReset(buttonSubmit) {
    buttonSubmit.buttonText = 'REDEFINIR SENHA';
    buttonSubmit.buttonSubmited = false;
  }

  /**
   * Função que exibe a mensagem de sucesso
   * @param name Nome(descrição) a ser exibida na mensagem
   * @param activeModal Variavel que ativa/desativa o modal
   */
   public successSendModal(name = '', activeModal?: NgbActiveModal) {
    SweetalertCustom.showAlertTimer('success', `${name} salva com sucesso!`)
      .then((result) => {
        if (result) {
          activeModal.close();
        }
      });
  }

  /**
   * Função que exibe a mensagem de sucesso e redireciona
   * @param name Nome(descrição) a ser exibida na mensagem
   */
   public successSendRedirect(name = '') {
    SweetalertCustom.showAlertTimer('success', `${name} salva com sucesso!`)
      .then(() => {
        this.router.navigate([`/auth/login`]);
      });
  }

  public successSendRedirectChanged(name = '', description = '') {
    SweetalertCustom.showAlertConfirm(`${name} salva com sucesso!`, { type: 'success' }, 'Ok', description)
      .then(() => this.router.navigate([`/auth/logout`]));
  }

  public successSendResetPassword() {
    SweetalertCustom.showAlertConfirm(`Senha resetada com sucesso!`, { type: 'success' }, 'Ok', 'Uma nova senha foi gerada e enviada para seu e-mail, para continuar faça login novamente.')
      .then(() => this.router.navigate(['/auth']));
  }

  getProfessor(){
    const usuario = this.storageService.getFromLocalStorage('usuario',true);
    return usuario;
  }

  getCurrentMatriculaId() {
    return this.storageService.getFromLocalStorage('matriculaId');
  }

  getCurrentAnoLetivo() {
    return this.storageService.getFromLocalStorage('anoLetivo');
  }

  getCurrentMatriculaNomeEscola() {
    const matriculaNomeEscola = this.storageService.getFromLocalStorage('matriculaNomeEscola',true);
    let [matricula, nomeEscola] = matriculaNomeEscola.matricula.split("|");
    return {matricula, nomeEscola};
  }

  public sendMatriculaAno(anoLetivo: {ano: string, id: string}, matriculaNomeEscola: {matricula: string, id: string}){
    const matricula = matriculaNomeEscola.matricula.slice(0, matriculaNomeEscola.matricula.indexOf("|"));
    this.storageService.setInLocalStorage('anoLetivo',anoLetivo);
    this.storageService.setInLocalStorage('anoLetivoId',anoLetivo.id);
    this.storageService.setInLocalStorage('matriculaNomeEscola',matriculaNomeEscola);
    this.storageService.setInLocalStorage('matriculaId',matriculaNomeEscola.id);
    this.storageService.setInLocalStorage('matriculaProfessor',matricula);

    this.router.navigate(['/']);
  }

  private setLocalInfo(anoLetivoId: string, matriculaId: string): void {
    localStorage.setItem(`${this.namespaceProject}.anoLetivoId`, btoa(JSON.stringify(anoLetivoId)));
    localStorage.setItem(`${this.namespaceProject}.matriculaId`, btoa(JSON.stringify(matriculaId)));
  }

  validatePassword( obj: any): Observable<any> {
    return this.httpClient.post(this.baseUrl + 'usuario/validar-senha', JSON.stringify(obj), this.headerOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)))
  }


}
