import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-generic-filter',
  templateUrl: './generic-filter.component.html',
})
export class GenericFilterComponent {
  @Input() disabled = false;
  @Output() filter = new EventEmitter();
  @Output() clear = new EventEmitter();

  onSubmit() {
    this.filter.emit();
  }

  onClear() {
    this.clear.emit();
  }
}
