<div class="info-bar">
  <div class="container">
    <div class="row justify-content-end align-items-center">
      <div class="mr-auto">
        <a
          [routerLink]="['/auth/selecionar-ano-matricula']"
          class="nav-link mouse-hover-pointer"
          title="Selecionar Ano e Matricula"
        >
          <h6 class="mb-0 text-white">Ano Letivo: {{anoLetivo}} - {{matricula}}</h6>
        </a>
        <h6 class="info-teste" *ngIf="ambienteQA">
          {{usuarioSession.nome?.split(' ')[0]}}, você está no ambiente de HOMOLOGAÇÃO/TREINAMENTO.
        </h6>
      </div>
      <ul class="nav-info">
        <li *ngFor="let menu of menusInfoBar">
          <a class="nav-item"
          [title]="menu.label"
          [routerLink]="menu.url"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: menu.exact }">
          {{ menu.label }}
          </a>
        </li>
        <li>
          <a (click)="clearAllFilters()" [routerLink]="['/auth/logout']" class="nav-item active" title="Sair"> Sair </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<nav>
  <div class="container">
    <div class="row h-100">
      <a routerLink="/" class="col-4 logo-container">
        <img src="assets/img/siage_Logo_01.png" alt="" srcset="" class="logo" />
      </a>

      <!-- Menu -->
      <ul class="nav-menu col-auto">
        <li *ngFor="let menu of menus">
          <a
            [title]="menu.label"
            class="nav-item"
            [routerLink]="menu.url"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: menu.exact }"
          >
            {{ menu.label }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
